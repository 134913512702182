import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle} page="home">
      <SEO
        title="Home"
        keywords={[
          `blog`,
          `samratchana`,
          `mahajyothi`,
          `baba`,
          `magazine`,
          `siva shankar baba`,
        ]}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h4 className="page-head-title">
            {data.site.siteMetadata.description}
          </h4>
          {/*Description subtext to be moved to config*/}
          <span className="page-head-description">
            Excerpts from speech delivered by Siddha Yogi Shri Siva Shankar Baba
          </span>
        </header>
      )}
      {/* <div className="alert-card">
        <div className="alert-title">Alert</div>
        <div className="alert-content">
          Rejoice! Now get dharshan of Lord Athi Varadhar at Kelambakkam <br />
          Click <a href="https://www.athivaradhartemple.org/">here</a> to know
          more!
        </div>
      </div> */}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      <div className="col link-block">
        <Link to="/archives">More</Link>
      </div>
      {/* <div className="row">
        <div className="kg-card col-9"><h4>About this site</h4>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently </div>
        <div className="kg-card">
          <h4>Upcoming Events</h4>
          <p>Please visit <a href="https://pooranabrahmam.org/">here</a></p>
        </div>
      </div> */}
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
